$primaryYellow: #FFD742;
$secondaryYellow: #FAE183;
$tertiaryYellow: #ffc845;

$primaryOrange: #D4902C;
$secondaryOrange: #EDBF15;

$primaryBrown: #54281F;
$secondaryBrown: #623a32;
$lightBrown: #a58e8a;

$primaryGray: #707070;
$secondaryGray: #f8f8f8;
$lightGray: #FAFAFA;
$lightGray2: #F2F2F2;

$black: #000000;
$white: #ffffff;
$tintedWhite: #eeeae9;

$errorRed: #b00020;
$errorRedSecondary: #fc0505;

$activeGreen: #14b63f;