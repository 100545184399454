$breakpoints: (
    small: 576px,
    medium: 769px,
    large: 1024px,
    xLarge: 1248px,
    xxLarge: 1496px
);

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Please make sure it is defined in `$breakpoints` map.";
    }
};