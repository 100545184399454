@import './styles/index.scss';
// Google Font
@import url('https://fonts.googleapis.com/css?family=Poppins|Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

// Update Bulma's global variables

$family-sans-serif: 'Poppins', sans-serif;
$family-secondary: 'Montserrat', sans-serif;
$grey-dark: $primaryGray;
$grey-light: $lightGray;
$primary: $primaryYellow;
$link: $primaryOrange;
$success: $activeGreen;
$text: $primaryBrown;
$danger: $errorRedSecondary;

$size-1: 22px;
$size-2: 16px;