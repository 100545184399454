@import '../../styles';

.buttons {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
    margin: 200px auto 50px;
    align-items: center;
    width: 350px;

    @include respond-to(large) {
        max-width: 600px;
        width: 100%;
        flex-direction: row;
    }
}